.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px 20px;
  margin-bottom: 10px;

  .title {
    display: flex;
    align-items: center;

    .title_text {
      margin-left: 10px;
    }
  }

  .right_item {
    display: flex;
    align-items: center;

    .edit_button,
    .delete_button {
      border: none;
      border-radius: 5px;
      background-color: #f1f2f7;
      cursor: pointer;

      .icon {
        width: 20px;
        color: #282828;
      }
    }

    .edit_button {
      margin-right: 5px;
    }
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .modal_content {
    background: #ffffff;
    width: 98vw;
    height: 98vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    &:focus {
      outline: none;
    }

    .modal_title {
      margin-bottom: 20px;
      font-size: 20px;
    }
  }
}
