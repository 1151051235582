.root {
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  .title_text {
    align-items: center;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 5px 10px;
    margin: 10px 0px;
  }
  .wrapper {
    // width: 100%;
    height: 100vh;
    background-color: #fff;
    padding: 5px 10px;
    margin-bottom: 10px;
    .form {
      .text_field {
        // width: 100%;
        padding: 5px 10px;
        margin-bottom: 10px;
        .select {
          // width: 100%;
          padding: 10px 1px;
          font-size: 16px;
        }
      }
      .button_wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 20px;

        .submit_button,
        .cancel_button {
          // width: 100%;
          height: 40px;
          font-weight: bold;
          font-size: 16px;
          border: none;
          border-radius: 5px;

          &:hover {
            cursor: pointer;
            background-color: #f7b52c;
          }
        }

        .submit_button {
          width: 98%;
          margin-bottom: 10px;
          background-color: #ffd5c9;
          color: #000;
        }

        .cancel_button {
          color: #282828;
        }
      }
    }
  }
}
