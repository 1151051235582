.form {
  width: 90vw;
  height: 100vh;
  // padding: 5px 10px;
  // margin-bottom: 10px;

  .button_wrapper {
    // overflow: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // margin-top: 100px;

    .submit_button,
    .cancel_button {
      font-weight: bold;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
      }
    }
    .submit_button {
      background-color: #33c9dc;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .cancel_button {
      color: #282828;
      margin-bottom: 200px;
    }
  }
}
