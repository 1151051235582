.text_field {
  margin-bottom: 15px;
  font-size: 16px;
  .row {
    width: 95%;
    font-size: 16px;
  }
  .alert {
    color: red;
  }
}

.date_table {
  margin-bottom: 20px;
  .date_field {
    width: 100%;
    height: 20px;
  }
  .alert {
    color: red;
    padding: 5px 15px;
  }
}
