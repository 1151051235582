.root {
  .app_bar {
    box-shadow: none;
    background-color: #f1f2f7;
    color: #282828;

    .tool_bar {
      padding: 0px 2px;
      display: flex;
      justify-content: space-between;
      .logo {
        display: flex;
      }
      .title {
        display: flex;
        align-items: center;
        color: #282828;
      }
    }
  }
  .button {
    margin: 0px 2px;
    font-size: 14px;
    // font-weight: bold;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    // background-color: #33c9dc;
  }
  .button:hover {
    // border-color: #fff;
    color: #fff;
    background-color: #00bcd4;
  }
}
