.root {
  width: 75vw;
  margin-top: 20px;
  margin-bottom: 20px;

  .form {
    .text_field {
      width: 100%;
    }

    .button_wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 20px;

      .submit_button,
      .cancel_button {
        width: 100%;
        height: 40px;
        font-weight: bold;
        font-size: 16px;
        border: none;
        border-radius: 5px;

        &:hover {
          cursor: pointer;
          background-color: #f7b52c;
        }
      }

      .submit_button {
        margin-bottom: 10px;
        background-color: #ffd5c9;
        color: #ffffff;
      }

      .cancel_button {
        color: #282828;
      }
    }
  }
}
