.wrapper {
  // display: flex;
  align-items: center;
  // justify-content: space-between;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 5px 10px;
  margin-bottom: 10px;

  .user {
    font-size: 14px;
    display: flex;
    padding: 5px 10px;
    color: #33c9dc;
    text-align: center;
    border-radius: 5px;
    background-color: #f3f3f3;
    .icon_male {
      margin-top: 4px;
      color: #2c387e;
    }
    .icon_female {
      margin-top: 4px;
      color: #ed4b82;
    }
    .like_button {
      // font-size: 14px;
      padding: 3px 5px;
      border: none;
      border-radius: 5px;
      background-color: #ffffff;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      .icon {
        color: #33c9dc;
      }
    }
    .like_count {
      font-size: 24px;
      border: none;
      border-radius: 5px;
      padding: 0px 2px;
      color: #33c9dc;
      text-align: center;
      margin-left: 6px;
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .love {
      font-size: 14px;
      border-radius: 5px;
      padding: 8px 5px;
      margin-left: 6px;
      margin-top: 6px;
      margin-bottom: 6px;
      color: #ffffff;
      background-color: #ed4b82;
    }
    .work {
      font-size: 14px;
      border-radius: 5px;
      padding: 8px 5px;
      margin-left: 6px;
      margin-top: 6px;
      margin-bottom: 6px;
      color: #ffffff;
      background-color: #2c387e;
    }
    .life {
      font-size: 14px;
      border-radius: 5px;
      padding: 8px 5px;
      margin-left: 6px;
      margin-top: 6px;
      margin-bottom: 6px;
      color: #ffffff;
      background-color: #00bcd4;
    }
    .target {
      font-size: 14px;
      border-radius: 5px;
      padding: 8px 5px;
      margin-left: 6px;
      margin-top: 6px;
      margin-bottom: 6px;
      color: #ffffff;
      background-color: #757575;
    }
    .presentation {
      font-size: 14px;
      border-radius: 5px;
      padding: 8px 5px;
      margin-left: 6px;
      margin-top: 6px;
      margin-bottom: 6px;
      color: #33c9dc;
      background-color: #ffffff;
    }
  }

  .title_text {
    padding: 4px 0px;
    font-size: 16px;
    margin-left: 10px;
    // text-align: center;

    .datetime {
      font-size: 12px;
      color: #cccccc;
    }
  }

  .right_item {
    display: flex;
    align-items: center;

    .edit_button,
    .like_button {
      border: none;
      border-radius: 5px;
      background-color: #f1f2f7;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 14px;
        color: #33c9dc;
      }
    }

    .edit_button {
      margin-right: 5px;
    }
  }
}
// .modal {
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .modal_content {
//     background: #ffffff;
//     width: 98vw;
//     height: 98vh;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     border-radius: 5px;

//     &:focus {
//       outline: none;
//     }

//     .modal_title {
//       margin-bottom: 20px;
//       font-size: 20px;
//     }
//   }
// }
