.text_field {
  // width: 100%;
  padding: 5px 10px;
  margin-bottom: 10px;
  .row {
    width: 95%;
    font-size: 16px;
  }
  .alert {
    color: red;
    padding: 0px 15px;
  }
  .select {
    // width: 100%;
    padding: 10px 1px;
    font-size: 16px;
  }
}
.button_wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;

  .submit_button,
  .cancel_button {
    // width: 100%;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
    border: none;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  .submit_button {
    // margin-bottom: 10px;
    background-color: #ffd5c9;
    color: #ffffff;
  }

  .cancel_button {
    color: #282828;
  }
}
