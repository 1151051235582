.root {
  border-radius: 5px;
  background-color: #ffffff;

  .edit_button,
  .delete_button {
    border: none;
    border-radius: 5px;
    background-color: #00bcd4;
    cursor: pointer;
  }

  .edit_button {
    margin-right: 5px;
  }
}
