.root {
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  .text_field {
    width: 60vw;
    height: 40px;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    font-size: 16px;
    padding: 15px 5px;
    margin-left: 6px;
    margin-top: 80px;
    margin-bottom: 6px;
    // width: 100%;
  }
  .button {
    width: 60vw;
    height: 40px;
    align-items: center;
    display: flex;
    font-size: 16px;
    padding: 15px 5px;
    margin-left: 6px;
    margin-bottom: 6px;
    // width: 100%;
    .login_button {
      width: 100vw;
      border: none;
      border-radius: 5px;
      background-color: #f1f2f7;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .style_button {
      width: 150px;
      border: none;
      margin-left: 20px;
      border-radius: 5px;
      background-color: #f1f2f7;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
