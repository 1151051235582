.root {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  .title_text {
    align-items: center;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 5px 10px;
    margin: 10px 0px;
  }
}
