.presentation_wrapper {
  width: 100%;
  .presentation {
    font-size: 14px;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 6px;
    margin-top: 6px;
    margin-bottom: 10px;
    color: #33c9dc;
    background-color: #ffffff;
  }
  .edit_button {
    color: #33c9dc;
    background-color: #ffffff;
    border: none;
    font-weight: bold;
    font-size: 14px;
    padding: 0px 6px;
    cursor: pointer;
  }
}

.modal {
  width: 100vw;
  // height: 100vh;
  // display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;

  .modal_content {
    overflow: auto;
    background: #ffffff;
    width: 98vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    &:focus {
      outline: none;
    }

    .modal_title {
      width: 90vw;
      height: 90vh;
      margin-top: 400px;
      margin-bottom: 10px;
      font-size: 16px;
      .target_user {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .user {
          display: flex;
          padding: 5px 10px;
          text-align: center;
          .target_username {
            align-items: center;
            justify-content: center;
            display: flex;
            font-weight: bold;
            font-size: 20px;
          }
          .icon_male {
            margin-top: 5px;
            color: #2c387e;
          }
          .icon_female {
            margin-top: 5px;
            color: #ed4b82;
          }
          .like_button {
            font-size: 18px;
            padding: 5px 5px;
            border: none;
            border-radius: 5px;
            background-color: #ffffff;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            .icon {
              color: #33c9dc;
            }
          }
          .like_count {
            font-size: 34px;
            border: none;
            border-radius: 5px;
            color: #33c9dc;
            text-align: center;
            margin-left: 6px;
            margin-top: 80px;
            margin-bottom: 0px;
            padding: 0px 2px;
          }
          .love {
            font-size: 14px;
            border-radius: 5px;
            padding: 15px 5px;
            margin-left: 6px;
            margin-top: 80px;
            margin-bottom: 6px;
            color: #ffffff;
            background-color: #ed4b82;
          }
          .work {
            font-size: 14px;
            border-radius: 5px;
            padding: 15px 5px;
            margin-left: 6px;
            margin-top: 80px;
            margin-bottom: 6px;
            color: #ffffff;
            background-color: #2c387e;
          }
          .life {
            font-size: 14px;
            border-radius: 5px;
            padding: 15px 5px;
            margin-left: 6px;
            margin-top: 80px;
            margin-bottom: 6px;
            color: #ffffff;
            background-color: #00bcd4;
          }
          .target {
            font-size: 14px;
            border-radius: 5px;
            padding: 15px 5px;
            margin-left: 6px;
            margin-top: 80px;
            margin-bottom: 6px;
            color: #ffffff;
            background-color: #757575;
          }
        }
      }
      .target_text {
        padding: 5px 5px;
        border-radius: 5px;
        margin-bottom: 10px;
        background-color: #33c9dc;
        color: #ffffff;
        font-size: 14;
      }
    }
  }
}
