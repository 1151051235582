.root {
  background-color: #ffffff;
  height: 98vh;
  width: 98vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 5px;

  .wrapper {
    height: 98vh;
    width: 98vw;
    border-radius: 10px;
    background-color: #f1f2f7;
    padding: 10px 10px;
  }
}
