.root {
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    height: 100vh;
    width: 100vw;
    border-radius: 10px;
    background-color: #f1f2f7;
    padding: 5px 10px;
  }
}
